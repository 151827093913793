.ReactModal__Overlay {
	@apply bg-transparent;
	@apply fixed;
	@apply top-0;
	@apply bottom-0;
	@apply left-0;
	@apply right-0;
	z-index: 2048;

	@apply transition;
	@apply ease-in-out;
	@apply duration-300;
}

.ReactModal__Content {
	outline: none !important;
}

.ReactModal__Overlay--after-open {
	@apply bg-black-o-60;
}

.ReactModal__Overlay--before-close {
	@apply bg-transparent;
}

.ReactModal__Content .modal-close {
	@apply cursor-pointer;
}

body.ReactModal__Body--open {
	@apply overflow-hidden;
}

/* TYPE: RIGHT ALIGNED */
.ReactModal__Content.type-right {
	@apply absolute;
	@apply right-0;
	@apply top-0;
	@apply bg-white;
	@apply h-full;
	@apply w-full;
	@apply md:w-auto;
	@apply md:max-w-lg;
	@apply md:min-w-[35%];
	@apply translate-x-full;

	@apply transition;
	@apply ease-in-out;
	@apply duration-300;
}

.ReactModal__Content--after-open.type-right {
	@apply translate-x-0;
}

.ReactModal__Content--before-close.type-right {
	@apply translate-x-full;
}

.ReactModal__Content .modal-inner-content {
	@apply py-5 px-10;
}

/* TYPE: FULLSCREEN */
.ReactModal__Content.type-fullscreen, .ReactModal__Content.type-small {
	@apply absolute;
	@apply bg-white;
	height: 90%;
	width: 90%;
	left: 50%;
	top: 50%;

	@apply rounded-md;
	@apply -translate-x-1/2;
	@apply -translate-y-1/2;
	@apply scale-50;
	@apply opacity-0;
	@apply transition;
	@apply ease-in-out;
	@apply duration-300;
	@apply flex;
	@apply flex-col;
}

.ReactModal__Content.type-small {
	height: 85%;
	width: 90%;
	@apply max-w-lg;
}

.ReactModal__Content.type-fullscreen, .ReactModal__Content.type-small .modal-inner-content {
	@apply p-0;
}

.ReactModal__Content--after-open.type-fullscreen, .ReactModal__Content--after-open.type-small {
	@apply scale-100;
	@apply opacity-100;
}

.ReactModal__Content--before-close.type-fullscreen, .ReactModal__Content--before-close.type-small {
	@apply scale-50;
	@apply opacity-0;
}