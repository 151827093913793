@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
	height: 100%;
	min-width: 100%;
	min-height: 100%;
}

body {
	font-smooth: antialiased;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-text-size-adjust: 100%;
	-webkit-overflow-scrolling: touch;
}

.c--container {
	@apply w-[85%] mx-auto;
}

.c--container-responsive {
	@apply w-full sm:w-[85%] mx-auto;
}

.mt-spacing {
	@apply mt-12 md:mt-24
}

/* TODO: Make a more elegant solution to this */
.container-breakout {
	margin-left: -8.8%;
	margin-right: -8.8%;
}

.swiper {
	height: calc(100% - 7.5rem);
}

.fb_iframe_widget iframe {
	@apply outline-none;
}
